@font-face {
  font-family: "Helvetica Now Display Bold";
  src: local("HelveticaNowDisplay-Bold"),
  url(../public/assets/fonts/HelveticaNowDisplay-Bold.ttf) format("truetype");
  font-display: swap;
}

:root {
  --font-size: 20px;
  --color: gray;
  --transition: 0.3s;
  --hover-font-size: 28px;
  --hover-color: black;
  --linkedin-color: #0077b5;
  --facebook-color: #3b5998;
  --twitter-color: #00acee;
}

html,
body {
  scroll-behavior: smooth;
  /* cursor: default; */
  overflow-x: hidden;
  background-color: rgb(255, 255, 255);
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica Now Display Bold", Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

code {
  font-family: "Helvetica Now Display Bold", Times, serif, "Courier New",
    monospace;
}
