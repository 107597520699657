.projects-section {
  background-color: #e2e2e2;
  background-image: url("https://www.transparenttextures.com/patterns/rocky-wall.png");
  padding-bottom: 5rem;
  position: relative;
}

.projects {
  margin: 0 5vw 0 5vw;
  padding-top: 4rem;
}

.projects-text {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.projects-text h3 {
  color: #72797c;
  line-height: 1.6rem;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  /* max-width: 1500px; */
}
