.contact-section {
  background-color: #0d0d0d;
  /* background-image: url("https://www.transparenttextures.com/patterns/concrete-wall.png"); */
  background-image: url("https://www.transparenttextures.com/patterns/black-felt.png");
  padding-bottom: 5rem;
  position: relative;
}

.contact {
  margin: 0 5vw 0 5vw;
  padding-top: 4rem;
  color: white;
}

.contact-text {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  color: #fff;
}

.contact-text h3 {
  color: #a1a4a8;
  line-height: 1.6rem;
}

.contact-info h3 {
  font-size: 4.145rem;
  line-height: 3.875rem;
  padding-top: 2px;
  padding-bottom: 3px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.07);
  letter-spacing: normal;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.contact-info ul {
  list-style: none;
  padding: 0;
  line-height: 2rem;
}

.wrapper {
  min-width: 238px;
}

.wrapper > * {
  padding: 1em;
}

.contact-info h3,
.contact-info ul {
  text-align: center;
  margin: 0 0 1rem 0;
}

.contact-text-area h3 {
  margin-bottom: 1rem;
}

/* FORM STYLES */
.contact-text-area form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  line-height: 2rem;
}

.contact-text-area form label {
  display: block;
}

.contact-text-area form p {
  margin: 0;
}

.contact-text-area form .full {
  grid-column: 1 / 3;
}

.contact-text-area form button,
.contact-text-area form input,
.contact-text-area form textarea {
  font: inherit;
  width: 100%;
  padding: 1em;
  border: 1px solid #ebebeb;
  border-radius: 18px;
}

.contact-text-area form input:focus,
.contact-text-area form select:focus,
.contact-text-area form textarea:focus,
.contact-text-area form button:focus {
  outline: none;
  opacity: 0.9;
}

.contact-text-area form button {
  background: rgba(214, 211, 211, 0.5);
  border: 0;
  text-transform: uppercase;
}

.contact-text-area form button:hover,
.contact-text-area form button:focus {
  background: rgba(214, 211, 211, 0.3);
  color: #fff;
  outline: 0;
  transition: background-color 0.2s ease-out;
}

/* LARGE SCREENS */
@media (min-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

.submit-success {
  background: rgba(129, 199, 101, 0.5);
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 1em;
  font: inherit 400 13.3333px;
  text-transform: uppercase;
  border-radius: 18px;
}

@media (max-width: 508px) {
  .contact-info h3 {
    font-size: 3.545rem;
    line-height: 2.875rem;
  }
  .contact-text-area form {
    display: block;
  }
  .contact-text-area form p {
    margin: 10px 0;
  }
}

@media (max-width: 508px) {
  li {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .contact-text-area form button,
  .contact-text-area form input,
  .contact-text-area form textarea {
    font-size: 0.9rem;
  }
}
