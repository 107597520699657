* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100px;
  min-width: 320px;
  z-index: 10;
}

.logo {
  display: block;
  position: relative;
  float: left;
  margin: 20px 0 0 20px;
}

.logo img {
  height: auto;
  min-width: 150px;
  max-width: 180px;
}

/* MENU STYLES */
.menu-wrap {
  position: relative;
  height: 46px;
  float: right;
  z-index: 1;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px 30px 0 0;
  width: 42px;
  height: 42px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.menu-wrap .toggler:hover + .hamburger {
  opacity: 0.8;
  letter-spacing: 4px;
}

.menu-wrap .hamburger {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 42px;
  margin: 30px 30px 0 0;
  color: black;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 13px;
  transition: ease-in 0.1s;
  z-index: 1;
}

/* Hamburger Line */
.menu-wrap .hamburger > div {
  position: relative;
  flex: none;
  width: 24%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -7px;
  width: 100%;
  height: 3px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger > div::after {
  top: 7px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
  background: #fff;
}

.menu-wrap .hamburger > p {
  margin-left: 6px;
  transition: ease-in 0.2s;
}

.menu-wrap .toggler:checked + .hamburger > p {
  color: #fff;
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.menu-wrap .toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

/* Show Menu */
.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
  transform: scaleY(1);
  /* transform-origin: top; */
  transition-duration: 0.5s;
  transition: transform 0.4s ease-in;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease-in 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu > div {
  background: rgba(15, 20, 30, 0.97);
  /* border-radius: 50%; */
  width: 100%;
  height: 100%;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.4s ease-in;
}

.menu-wrap .menu > div > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul {
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.copyrights {
  position: absolute;
  align-self: center;
  bottom: 3vh;
  height: 5%;
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.75rem;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 600;
  padding: 1rem;
  transition: ease-in 0.2s;
}

.menu-wrap .menu > div > div > ul > li:hover {
  opacity: 0.4;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 70px;
  }
  .logo {
    margin: 30px 0 0 30px;
  }
  .logo img {
    width: 25%;
    height: auto;
    min-width: 120px;
    max-width: 170px;
  }
  .menu-wrap .hamburger {
    margin: 30px 30px 0 0;
  }
  .menu-wrap .toggler {
    margin: 30px 30px 0 0px;
  }
}

@media only screen and (max-width: 768px) {
  .menu-wrap .toggler:hover + .hamburger {
    opacity: 1;
    letter-spacing: 2px;
  }
  .logo {
    margin: 20px 0 0 14px;
  }
  .menu-wrap .hamburger {
    margin: 20px 20px 0 0;
  }
  .menu-wrap .toggler {
    margin: 20px 14px 0 0px;
  }
  .menu-wrap .menu > div > div > ul > li {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .menu-wrap .hamburger > p {
    visibility: hidden;
  }
  .text {
    width: 100%;
  }
  .menu-wrap .menu > div > div > ul > li {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 350px) {
  .logo img {
    min-width: 100px;
  }
}
