$marqueeTW: 184vw;
$marqueeAD: 20.5s;
@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("HelveticaNowDisplay-Regular"),
  url(../../../public/assets/fonts/HelveticaNowDisplay-Regular.ttf) format("truetype");
  font-display: swap;
}
/* MARQUEE TEXT */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes home-loaded-fancy {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
@keyframes mouse__icon {
  from {
    opacity: 1;
    top: 4px;
  }
  to {
    opacity: 1;
    top: 13px;
  }
}
@keyframes home-loaded {
  0%,
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.intro {
  display: flex;
  flex-direction: column;
  margin: 0 5vw 0 5vw;
  z-index: -1;
  height: 100vh;
  width: 90vw;
  animation: home-loaded 1s linear;
}
.cursor {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  backdrop-filter: invert(1);
  z-index: 100;
}
.picture {
  position: relative;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  > img {
    position: absolute;
    bottom: -30px;
    right: 60px;
    width: auto;
    height: 90%;
  }
}
.marquee {
  position: fixed;
  width: 90vw;
  top: calc(100px + 5vh);
  font-size: 16vw;
  span {
    display: inline-block;
    white-space: nowrap;
    color: #f8f8f8;
    width: $marqueeTW;
    text-shadow: $marqueeTW 0 currentColor,
      calc(#{$marqueeTW * 2}) 0 currentColor,
      calc(#{$marqueeTW * 3}) 0 currentColor,
      calc(#{$marqueeTW * 4}) 0 currentColor;
    will-change: transform;
    animation: marquee $marqueeAD linear infinite;
  }
}
.home-text {
  display: flex;
  position: absolute;
  bottom: 20vh;
  flex-direction: column;
  width: 45%;
  max-width: 850px;
}
.home-text-name {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  color: #878a8f;
  font-size: 1rem;
  opacity: 0.7;
  z-index: 0;
}
.home-text-title {
  font-size: 4.5rem;
  line-height: 5rem;
  padding-top: 2px;
  padding-bottom: 10px;
  font-weight: 800;
}
.home-text-about {
  font-family: "Helvetica Now Display Regular";
  font-size: 1.6rem;
  line-height: 2rem;
  padding-top: 1px;
  padding-bottom: 4px;
  font-weight: 500;
  color: #878a8f;
  letter-spacing: 0.1px;
}
.fancy__color {
  color: black;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background: black;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 250ms ease-in;
    animation: home-loaded-fancy 1s ease-in-out;
    animation-delay: 3s;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}
.scroll__icon {
  position: absolute;
  bottom: 10vh;
  margin-top: 20px;
  height: 5vh;
  width: 3vh;
  align-self: center;
}
.wheel__icon {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mouse__icon {
  position: relative;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 26px;
  border: 2px solid black;
  border-radius: 60px;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    background: rgb(90, 86, 86);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 1;
    animation: mouse__icon 1s ease-in-out infinite alternate;
  }
}
::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #272727;
}
@media only screen and (min-width: 1130px) {
  .intro {
    min-height: 800px;
  }
  .picture {
    > img {
      bottom: -20px;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .picture {
    > img {
      right: 30px;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .picture {
    > img {
      right: 0px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .picture {
    > img {
      right: -15px;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .picture {
    > img {
      right: -50px;
    }
  }
  .home-text {
    width: 55%;
    bottom: 15vh;
  }
}
@media only screen and (max-width: 1000px) {
  .picture {
    > img {
      right: -90px;
    }
  }
}
@media only screen and (min-height: 1100px) and (max-width: 1024px) {
  .picture {
    > img {
      height: 65%;
      bottom: 30vh;
      top: unset;
      right: -45px;
    }
  }
  .home-text {
    width: 75%;
    bottom: 15vh;
  }
  .scroll__icon {
    bottom: 6vh;
  }
  .cursor {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .picture {
    > img {
      height: 70%;
      bottom: 20vh;
      top: unset;
      right: -45px;
    }
  }
  .home-text {
    width: 80%;
    bottom: 10vh;
  }
  .home-text-name {
    font-size: 0.8rem;
  }
  .home-text-title {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
  .home-text-about {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .scroll__icon {
    bottom: 5vh;
  }
  .cursor {
    display: none;
  }
}
@media only screen and (max-height: 750px) and (max-width: 768px) {
  .picture {
    > img {
      bottom: 16vh;
      right: -15px;
    }
  }
  .home-text-title {
    font-size: 2.6rem;
    line-height: 2.9rem;
  }
  .home-text-about {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .scroll__icon {
    display: none;
  }
}
@media only screen and (min-height: 680px) and (max-width: 600px) {
  .picture {
    > img {
      height: 70%;
      bottom: 22vh;
    }
  }
}
@media only screen and (max-width: 480px) {
  .picture {
    > img {
      height: auto;
      width: 80%;
      bottom: unset;
      top: 8vh;
      right: -20px;
    }
  }
  .marquee {
    top: 20vh;
    font-size: 50vw;
    span {
      $marqueeTW: 600vw;
      $marqueeAD: 25s;
      width: $marqueeTW;
      text-shadow: $marqueeTW 0 currentColor,
        calc(#{$marqueeTW * 2}) 0 currentColor,
        calc(#{$marqueeTW * 3}) 0 currentColor,
        calc(#{$marqueeTW * 4}) 0 currentColor;
      animation: marquee $marqueeAD linear infinite;
    }
  }
  .home-text {
    width: 90%;
    bottom: 8vh;
  }
  .home-text-name {
    font-size: 0.6rem;
  }
  .home-text-title {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .home-text-about {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .scroll__icon {
    display: none;
  }
  .fancy__color {
    ::after {
      height: 1px;
      bottom: -3px;
    }
  }
}
@media only screen and (min-height: 800px) and (max-width: 480px) {
  .picture {
    > img {
      width: 100%;
      bottom: unset;
      top: 8vh;
    }
  }
}
@media only screen and (max-width: 320px) {
  .picture {
    > img {
      width: 100%;
      top: 4.5vh;
      right: -20px;
    }
  }
  .home-text {
    width: 90%;
    bottom: 11vh;
  }
  .home-text-title {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .home-text-about {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .scroll__icon {
    bottom: 45px;
  }
}
@media only screen and (min-height: 650px) and (max-width: 360px) {
  .picture {
    > img {
      top: 10vh;
    }
  }
}

@media only screen and (max-height: 630px) and (max-width: 400px) {
  .picture {
    > img {
      width: 100%;
    }
  }
  .home-text-title {
    font-size: 2.1rem;
    line-height: 2rem;
  }
  .home-text-about {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

@media only screen and (max-height: 530px) and (max-width: 400px) {
  .home-text-title {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .home-text-about {
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
