.footer {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  bottom: 0;
  margin-top: 2rem;
  background-color: #dbd9d9;
  z-index: 9;
}

.footer-copyrights {
  font-size: small;
  font-family: "Helvetica Now Display Regular";
  font-weight: 500;
}

.footer-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 100%;
  margin-left: 30px;
}

.footer-icon {
  font-size: var(--font-size);
  color: var(--color);
  transition: var(--transition);
}

.footer-icon:hover {
  font-size: var(--hover-font-size);
  transform: rotate(360deg);
}

.another:hover {
  color: var(--hover-color);
}

.fa-linkedin:hover {
  color: var(--linkedin-color);
}

.fa-facebook:hover {
  color: var(--facebook-color);
}

.fa-twitter:hover {
  color: var(--twitter-color);
}
