.card {
  display: flex;
  width: 800px;
  height: 700px;
  margin: 2rem 3.5rem;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  transition: 2s ease-in-out;
  -webkit-box-shadow: 0px 10px 41px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 10px 41px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 10px 41px 0px rgba(0, 0, 0, 0.7);
}

.card:hover .enter-icon {
  opacity: 0;
}

.enter-icon {
  transition: 0.3s ease-in;
  position: absolute;
  opacity: 0.7;
  left: -3.5rem;
  bottom: 1rem;
  animation: more_info_icon 2s ease-in-out infinite alternate;
}

@keyframes more_info_icon {
  from {
    transform-origin: bottom center;
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-5deg);
  }
}

.enter-icon img {
  width: 60%;
  height: auto;
}

.moshal-social {
  background-image: url(../../../../public/assets/images/projects/moshal-social.jpg);
}

.boggle {
  background-image: url(../../../../public/assets/images/projects/boggle.jpg);
}

.eagle-vision {
  background-image: url(../../../../public/assets/images/projects/eaglevision.jpg);
}

.robofriends {
  background-image: url(../../../../public/assets/images/projects/robofriends.jpg);
}

.portfolio {
  background-image: url(../../../../public/assets/images/projects/portfolio.jpg);
}
.mcdownloads {
  background-image: url(../../../../public/assets/images/projects/mcdownloads.jpg);
}
.fruitty {
  background-image: url(../../../../public/assets/images/projects/fruitty.jpg);
}
.card-bg {
  position: relative;
  color: white;
  height: 100%;
  width: 100%;
  background-color: rgba(31, 29, 29, 0.9);
  opacity: 0;
  transition: 0.4s ease-in-out;
}
.card-text {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 15%;
}

.card-text h1 {
  margin-bottom: 5%;
}

.card-text h3 {
  text-align: center;
}

.card:hover {
  filter: grayscale(0%);
}

.card:hover .card-text {
  display: flex;
}

.card:hover .card-bg {
  opacity: 1;
  z-index: 2;
}
.project-techs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;
}
.project-techs span {
  border: 1px dotted white;
  border-radius: 18px;
  padding: 0.5em;
  margin: 0.5em;
}

.project-buttons {
  margin-top: 20%;
}

.project-buttons a {
  color: white;
  background: linear-gradient(to top, white, 1%, transparent);
  padding: 15px;
  border: 2px solid white;
  margin: 2em;
  text-decoration: none;
  transition: 0.1s ease-in;
}

.project-buttons :hover {
  background: linear-gradient(to top, white, 40%, transparent);
  border-radius: 18px;
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 700px;
    height: 600px;
    margin: 2rem 2.5rem;
  }
}

@media only screen and (max-width: 870px) {
  .card {
    width: 600px;
    height: 500px;
    margin: 2rem 1.5rem;
  }
}

@media only screen and (max-width: 724px) {
  .card {
    width: 500px;
    height: 400px;
  }
  .enter-icon {
    left: -2.5rem;
    bottom: 1rem;
  }
  .enter-icon img {
    width: 50%;
  }
  .card-text h1 {
    font-size: 22px;
    margin-bottom: 5%;
  }
  .card-text h3 {
    font-size: 16px;
  }
  .project-techs {
    margin-top: 1em;
  }
  .project-techs span {
    font-size: 14px;
  }
  .project-buttons {
    margin-top: 15%;
  }
  .project-buttons a {
    font-size: 15px;
    padding: 12px;
    margin: 1.5em;
  }
}

@media only screen and (max-width: 560px) {
  .card {
    width: 400px;
    height: 400px;
  }
  .enter-icon {
    left: -2.5rem;
    bottom: 1rem;
  }
  .enter-icon img {
    width: 40%;
  }
  .project-buttons {
    margin-top: 10%;
  }
  .project-buttons a {
    font-size: 14px;
    padding: 8px;
    margin: 1em;
  }
}

@media only screen and (max-width: 500px) {
  .card {
    width: none;
    height: 400px;
    margin: 2rem 0rem;
  }
  .enter-icon {
    left: 1rem;
    bottom: 1rem;
  }
  .eagle-vision {
    background-position: 56%;
  }
  .portfolio {
    background-position: 40%;
  }
}

@media only screen and (max-width: 400px) {
  .card-text {
    margin: 0 10%;
  }
  .card-text h1 {
    font-size: 20px;
    margin-bottom: 1em;
  }
  .card-text h3 {
    font-size: 15px;
  }
  .project-techs {
    margin-top: 1em;
  }
  .project-techs span {
    font-size: 13px;
  }
  .project-buttons {
    margin-top: 1.2em;
  }
  .project-buttons a {
    font-size: 12px;
    padding: 7px;
    margin: 0.8em;
  }
}
